import styles from "./AdvantageItem.module.css";
export default function AdvantageItem({ icon, title, description }) {
  return (
    <div className={`${styles.iconBox} d-flex position-relative`}>
      <i className={`bi ${icon} flex-shrink-0`}></i>
      <div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
}
