import AdvantageItem from "./AdvantageItem";

export default function AdvantageList({ advantages }) {
  return (
    <ul style={{ padding: 0 }}>
      {advantages.map(({ icon, title, description }, index) => (
        <AdvantageItem
          icon={icon}
          title={title}
          description={description}
          key={index}
        />
      ))}
    </ul>
  );
}
