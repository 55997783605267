import styles from "./Header.module.css";

export default function Logo() {
  return (
    <div className={`${styles.logo} d-flex align-items-center`}>
      <img
        className={styles.logoImage}
        src="/assets/img/logo-light.png"
        alt=""
      />
    </div>
  );
}
