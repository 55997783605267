import "./App.css";

import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import { Helmet } from "react-helmet";
import Homepage from "./pages/Homepage";
import Services from "./components/Services";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="AgriDroneMx" />
        <meta
          property="og:description"
          content="Explora nuestro enfoque líder en fumigación con dron y análisis multiespectral. Descubre cómo combinamos tecnología de vanguardia con prácticas agrícolas sostenibles, llevando la agricultura al siguiente nivel con innovación y respeto por el medio ambiente."
        />
        <meta property="og:image" content="url('/assets/logo.png')" />
        <title>AgriDroneMX</title>
        <meta
          name="description"
          content="Explora nuestro enfoque líder en fumigación con dron y análisis multiespectral. Descubre cómo combinamos tecnología de vanguardia con prácticas agrícolas sostenibles, llevando la agricultura al siguiente nivel con innovación y respeto por el medio ambiente."
        />
      </Helmet>
      <Header />
      <Homepage />
      <main id="main">
        <Services />
        <AboutUs />
        <ContactUs />
      </main>
      <Footer />
    </>
  );
}

export default App;
