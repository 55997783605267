import AdvantageList from "./AdvantageList";
import styles from "./ImageListItem.module.css";
export default function ImageListItem({ item, index }) {
  const isEven = index % 2 === 0;
  const { imagePath, advantages, title, description, sectionId } = item;
  return (
    <li>
      <section className={styles.altServices} id={sectionId}>
        <div className="container">
          <div className="row justify-content-around gy-4">
            {isEven && (
              <>
                <div
                  className={`col-lg-6 ${styles.imgBg}`}
                  style={{ backgroundImage: imagePath }}
                ></div>
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3> {title}</h3>
                  <p>{description}</p>
                  <AdvantageList advantages={advantages} />
                </div>
              </>
            )}
            {!isEven && (
              <>
                <div className="col-lg-5 d-flex flex-column justify-content-center">
                  <h3> {title}</h3>
                  <p>{description}</p>
                  <AdvantageList advantages={advantages} />
                </div>
                <div
                  className={`col-lg-6 ${styles.imgBg}`}
                  style={{ backgroundImage: imagePath }}
                ></div>
              </>
            )}
          </div>
        </div>
      </section>
    </li>
  );
}
