import ImageListItem from "./ImageListItem";

export default function ImageList({ data }) {
  return (
    <ul style={{ listStyle: "none" }}>
      {data.map((item, index) => (
        <ImageListItem key={index} item={item} index={index} />
      ))}
    </ul>
  );
}
