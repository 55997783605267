/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./PageNav.module.css";
import { useEffect, useRef, useState } from "react";

export default function PageNav() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log(menuRef);
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !burgerRef.current.contains(event.target)
      ) {
        console.log("Click outside");
        setIsOpen((value) => !value);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setIsOpen(false);
  };
  return (
    <>
      <i
        ref={burgerRef}
        onClick={() => setIsOpen((value) => !value)}
        className={`${styles["mobile-nav-toggle"]} ${styles["mobile-nav-show"]}  bi bi-list`}
      ></i>
      {/*<i*/}
      {/*  className={`${styles["mobile-nav-toggle"]} ${styles["mobile-nav-hide"]}  bi bi-list`}*/}
      {/*></i>*/}
      <nav
        ref={menuRef}
        id="navbar"
        className={`${styles.navbar} ${isOpen ? styles.show : styles.hide}`}
      >
        <ul>
          <li onClick={() => scrollToSection("services")}>
            <a>Servicios</a>
          </li>
          <li onClick={() => scrollToSection("aboutUs")}>
            <a>Acerca de nosotros</a>
          </li>
          <li onClick={() => scrollToSection("contactUs")}>
            <a>Contacto</a>
          </li>
        </ul>
      </nav>
    </>
  );
}
