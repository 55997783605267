import styles from "../pages/ContactUs.module.css";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const form = useRef();

  function handleSubmit(event) {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_smdvhnj",
        "template_cip6ppu",
        form.current,
        "SGye_stR60Gz1f_Me",
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
      );
  }

  return (
    <div className="col-lg-6">
      <form
        ref={form}
        onSubmit={handleSubmit}
        className={styles["php-email-form"]}
      >
        <div className="row gy-4">
          <div className={`${styles["form-group"]} col-lg-6 `}>
            <input
              id="name"
              type="text"
              name="user_name"
              className="form-control"
              placeholder="Tu nombre"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="valid-feedback">Looks good!</div>
            <div className="invalid-feedback">Please choose a username.</div>
          </div>
          <div className={`${styles["form-group"]} col-lg-6 `}>
            <input
              type="email"
              name="user_email"
              className="form-control"
              placeholder="Tu correo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className={styles["form-group"]}>
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Asunto"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className={styles["form-group"]}>
          <textarea
            className="form-control"
            name="message"
            rows="5"
            placeholder="Mensaje"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
          ></textarea>
        </div>
        {/*<div className="my-3">*/}
        {/*  <div className="loading">Loading</div>*/}
        {/*  <div className="error-message"></div>*/}
        {/*  <div className="sent-message">*/}
        {/*    Your message has been sent. Thank you!*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="text-center">
          <button type="submit">Enviar correo</button>
        </div>
      </form>
    </div>
  );
}
