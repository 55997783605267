export default function SectionTitle({ title, description = "" }) {
  return (
    <section style={{ padding: 0 }}>
      <div className="container">
        <div className="section-header" style={{ paddingBottom: 0 }}>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </section>
  );
}
