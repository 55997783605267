/* eslint-disable */
import ImageList from "./ImageList";
import MainPageTitle from "./MainPageTitle";
import SectionTitle from "./SectionTitle";

const { services } = require("../data.json");

export default function Services() {
  const title = "Servicios";
  const description =
    "Nuestros servicios están diseñados para optimizar el rendimiento agrícola, reducir costos y promover prácticas sostenibles";
  return (
    <section id="services">
      <SectionTitle title={title} description={description} />
      <ImageList data={services} />
    </section>
  );
}
