import MainPageTitle from "../components/MainPageTitle";
import Carousel from "../components/Carousel";

export default function Homepage() {
  return (
    <section id="hero" className="hero">
      <MainPageTitle />
      <Carousel />
    </section>
  );
}
