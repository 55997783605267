import styles from "./Footer.module.css";
import data from "../data.json";
import { scrollToSection } from "../helpers/scrollToSection";
export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={`${styles["footer-content"]} position-relative`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-info">
                <h3>AgriDroneMx</h3>
                <p>
                  <br />
                  <strong>Teléfono:</strong> (222) 174-9062
                  <br />
                  <br />
                  <strong>Email:</strong> agridronemx@gmail.com
                  <br />
                  <br />
                </p>
                <div className={`${styles["social-links"]}`}>
                  <a
                    href="https://www.facebook.com/profile.php?id=61560110307290"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/agridronemx/"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a
                    href="https://wa.me/522221749062"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="bi bi-whatsapp"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className={`${styles["footer-links"]} col-lg-4 col-md-4`}>
              <h4>Nuestros servicios</h4>
              <ul>
                {data.services.map((service) => {
                  return (
                    <li onClick={() => scrollToSection(service.sectionId)}>
                      <p>{service.shortTitle}</p>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className={`${styles["footer-links"]} col-lg-4 col-md-4`}>
              <h4>Acerca de nosotros</h4>
              <ul>
                <li onClick={() => scrollToSection("quienesSomos")}>
                  <p>Quienes somos</p>
                </li>
                <li onClick={() => scrollToSection("mision")}>
                  <p>Misión</p>
                </li>
                <li onClick={() => scrollToSection("vision")}>
                  <p>Visión</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
