import styles from "./Header.module.css";
import Logo from "./Logo";
import PageNav from "./PageNav";

export default function Header() {
  return (
    <header
      id="header"
      className={`${styles.header} d-flex align-items-center`}
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <Logo />
        <PageNav />
      </div>
    </header>
  );
}
