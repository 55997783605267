export default function MainPageTitle() {
  return (
    <div className="info d-flex align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 data-aos="">
              Transformando la Agricultura con Tecnología de Vanguardia
            </h2>
            <p data-aos="">
              Sumérgete en nuestro compromiso con el futuro sostenible, donde la
              innovación tecnológica se fusiona con cada semilla plantada,
              redefiniendo la agricultura para generaciones venideras.
            </p>
            <a
              data-aos="fade-up"
              data-aos-delay="200"
              href="#get-started"
              className="btn-get-started"
            >
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
