import { useState } from "react";

const imageUrls = [
  "/assets/img/hero-carousel/carousel-1.jpeg",
  "/assets/img/hero-carousel/carousel-2.jpeg",
  "/assets/img/hero-carousel/carousel-3.jpeg",
  "/assets/img/hero-carousel/carousel-4.jpeg",
];

export default function Carousel() {
  const [imageIndex, setImageIndex] = useState(0);

  function nextImage() {
    setImageIndex((index) => (index + 1) % imageUrls.length);
  }

  function prevImage() {
    setImageIndex((index) => (index - 1 + imageUrls.length) % imageUrls.length);
  }
  return (
    <div
      id="hero-carousel"
      className="carousel slide"
      data-bs-ride="carousel"
      data-bs-interval="5000"
    >
      <div
        className="carousel-item active"
        style={{ backgroundImage: `url('${imageUrls[imageIndex]}')` }}
      ></div>

      <button
        onClick={prevImage}
        className="carousel-control-prev"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon bi bi-chevron-left"
          aria-hidden="true"
        ></span>
      </button>

      <button
        onClick={nextImage}
        className="carousel-control-next"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon bi bi-chevron-right"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  );
}
