import styles from "./ContactUs.module.css";
import ContactForm from "../components/ContactForm";
import SectionTitle from "../components/SectionTitle";

export default function ContactUs() {
  return (
    <section id="contactUs">
      <SectionTitle title="Contacto"></SectionTitle>
      <section id="contact" className={styles.contact}>
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6">
              <div
                className={`${styles["info-item"]} d-flex flex-column justify-content-center align-items-center`}
              >
                <i className="bi bi-map"></i>
                <h3>Nuestra dirección</h3>
                <p>Real del Oro, Veracruz.</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className={`${styles["info-item"]} d-flex flex-column justify-content-center align-items-center`}
              >
                <i className="bi bi-envelope"></i>
                <h3>¡Mándanos un correo!</h3>
                <p>agridronemx@gmail.com</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div
                className={`${styles["info-item"]} d-flex flex-column justify-content-center align-items-center`}
              >
                <i className="bi bi-telephone"></i>
                <h3>¡Llámanos!</h3>
                <p>2221749062</p>
              </div>
            </div>
          </div>

          <div className="row gy-4 mt-1">
            <div className="col-lg-6 ">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.7369254178184!2d-96.41186737381386!3d19.423769431853774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c4aa9da33392e3%3A0x420464c24eec53be!2s91663%20Real%20del%20Oro%2C%20Ver.!5e0!3m2!1ses-419!2smx!4v1716514487374!5m2!1ses-419!2smx"
                style={{ border: 0, width: "100%", height: "384px" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <ContactForm />
          </div>
        </div>
      </section>
    </section>
  );
}
