/* eslint-disable jsx-a11y/anchor-is-valid */
import "./AboutUs.module.css";
import SectionTitle from "../components/SectionTitle";

export default function AboutUs() {
  return (
    <section id="aboutUs" className="features section-bg">
      <SectionTitle title="Acerca de nosotros" />
      <br />
      <div className="container">
        <div id="quienesSomos">
          <h3>¿Quiénes somos?</h3>
          <p>
            Somos una empresa apasionada por la innovación en la agricultura,
            comprometida con la mejora continua y la sostenibilidad. Nuestro
            equipo está formado por expertos en tecnología, agrónomos y
            profesionales comprometidos con el desarrollo de soluciones que
            impulsen la eficiencia y la rentabilidad en el sector agrícola. Nos
            destacamos por nuestra dedicación a la excelencia, la
            responsabilidad ambiental y el apoyo a los agricultores para
            enfrentar los desafíos actuales y futuros.
          </p>
        </div>
        <ul className="nav nav-tabs row  g-2 d-flex">
          <li className="nav-item col-6">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#tab-2"
            >
              <h4>Mision</h4>
            </a>
          </li>
          <li className="nav-item col-6">
            <a
              className="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#tab-3"
            >
              <h4>Visión</h4>
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div className="tab-pane" id="tab-2">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                <h3>Misión</h3>
                <p>
                  Ser líderes en la revolución agrícola mediante la aplicación
                  de tecnologías innovadoras y sostenibles. Nos comprometemos a
                  ofrecer servicios de fumigación con drones de precisión y
                  análisis de cultivos con drones multiespectrales, optimizando
                  la productividad agrícola, reduciendo el impacto ambiental y
                  promoviendo la eficiencia en el uso de recursos.
                </p>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 text-center">
                <img
                  src="assets/img/features-2.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>

          <div className="tab-pane" id="tab-3">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                <h3>Visión</h3>
                <p>
                  Transformar la agricultura global a través de la adopción
                  masiva de soluciones tecnológicas avanzadas. Buscamos ser
                  reconocidos como la principal empresa especializada en
                  fumigación y análisis de cultivos mediante drones,
                  proporcionando servicios de alta calidad, contribuyendo al
                  desarrollo sostenible y mejorando la seguridad alimentaria en
                  todo el mundo.
                </p>
              </div>
              <div className="col-lg-6 order-1 order-lg-2 text-center">
                <img
                  src="assets/img/features-3.jpg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
